import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../baseQuery";

export const templatesApi = createApi({
  reducerPath: "templatesApi",
  baseQuery,
  refetchOnMountOrArgChange: true,
  tagTypes: ["templates", "template-list", "styles", "confirm"],
  endpoints: (builder) => ({
    getTemplateCategories: builder.query({
      query: () => `/template-categories`,
      providesTags: ["templates"],
    }),
    getStyleCategories: builder.query({
      query: () => `/styles`,
      providesTags: ["styles"],
    }),
    confirmTheme: builder.mutation({
      query: ({ template_id,package_id }) => ({
        url: `/site/create`,
        method: "POST",
        body: {
          template_id,
          package_id,
        },
      }),
      invalidatesTags: ["confirm"],
    }),
    getTemplateList: builder.query({
      query: ({ category_ids, keyword, style_ids, page }) =>
        `/templates?category_ids[]=${category_ids}&style_ids[]=${style_ids}&keyword=${keyword}&page=${page}`,
      providesTags: ["template-list"],
    }),
  }),
});

export const {
  useGetTemplateCategoriesQuery,
  useLazyGetTemplateListQuery,
  useGetStyleCategoriesQuery,
  useConfirmThemeMutation,
} = templatesApi;
