import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../baseQuery";

export const commonApi = createApi({
  reducerPath: "commonApi",
  baseQuery,
  tagTypes: ["all-modules","all-packages",'statistics'],
  endpoints: (builder) => ({
    getAllModules: builder.query({
      query: () => `/global/all-modules`,
      providesTags: ["all-modules"],
    }),
    getAllPackages: builder.query({
      query: () => `/global/packages`,
      providesTags: ["all-packages"],
    }),
    getStatistics: builder.query({
      query: () => `/global/statistics`,
      providesTags: ["statistics"],
    }),
  }),
});

export const { useGetAllModulesQuery, useGetAllPackagesQuery,useGetStatisticsQuery } = commonApi;
