import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  search: "",
  template_id: "",
  package_id: "",
};

const templateSlice = createSlice({
  name: "templateSlice",
  initialState,
  reducers: {
    setSearch: (state, { payload }) => {
      state.search = payload;
    },
    setTemplateId: (state, { payload }) => {
      state.template_id = payload;
    },
    setSelectedPackagesId: (state, { payload }) => {
      state.package_id = payload;
    },
  },
});

export const templateReducer = templateSlice.reducer;
export const { setSearch, setTemplateId ,setSelectedPackagesId} = templateSlice.actions;
