import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpenHamburger: false,
  menuTitles: "",
  countryCode: "+994",
  sizes: {
    x: 0,
    y: 0,
  },
  menuTitles: "",
  countryCode: "+994",
  dictionary: {},
  prevPath: "",
  stepNumber: "1",
  registerComponent: "sign-in",
  formType: {
    packageFrequency: "monthly",    
  },
};

const commonSlice = createSlice({
  name: "CommonSlice",
  initialState,
  reducers: {
    setHamburgerState: (state, { payload }) => {
      const isOpened = payload;
      state.isOpenHamburger = isOpened;
    },
    setMenuTitles: (state, { payload }) => {
      if (payload !== state.menuTitles) {
        state.menuTitles = payload;
      } else {
        state.menuTitles = "";
      }
    },
    setCountryCode: (state, { payload }) => {
      state.countryCode = payload;
    },
    setCount(state, { payload: { key, value } }) {
      state[key] = value;
    },
    setSearchName(state, { payload }) {
      state.searchName = payload;
    },
    setFormType: (state, { payload }) => {
      const { value, type } = payload;
      state.formType[type] = value;
    },

    resize(state, { payload }) {
      state.sizes = payload;
    },
    setDictionary(state, { payload }) {
      state.dictionary = payload;
    },
    setPrevPath(state, { payload }) {
      state.prevPath = payload;
    },
    deletePrevPath(state) {
      state.prevPath = null;
    },
    setStepNumber(state, { payload }) {
      state.stepNumber = payload;
    },
    setRegisterComponent(state, { payload }) {
      state.registerComponent = payload;
    },
  },
});

export const commonReducer = commonSlice.reducer;
export const {
  setHamburgerState,
  setMenuTitles,
  setCountryCode,
  setCount,
  setSearchName,
  resize,
  setDictionary,
  setPrevPath,
  deletePrevPath,
  setStepNumber,
  setFormType,
  setRegisterComponent,
} = commonSlice.actions;
