import { getFromLocale } from "@/utils";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const authApi = createApi({
  reducerPath: "authApis",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.API_FOR_LOGIN}/api`,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      headers.set("Accept", "application/json");
      headers.set("Authorization", `Bearer ${getFromLocale()}`);
      headers.set("Access-Control-Allow-Origin", "*");
      return headers;
    },
  }),
  tagTypes: ["login", "register", "logout"],
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (body) => ({
        url: "/auth/login",
        method: "POST",
        body,
      }),
      invalidatesTags: ["login"],
    }),
    individualRegister: builder.mutation({
      query: (body) => ({
        url: "/auth/register",
        method: "POST",
        body,
      }),
      invalidatesTags: ["register"],
    }),
    me: builder.mutation({
      query: (body) => ({
        url: "/auth/me",
        method: "POST",
        body,
      }),
      invalidatesTags: ["login"],
    }),
    logOut: builder.mutation({
      query: (body) => ({
        url: "/auth/logout",
        method: "POST",
        body,
      }),
      invalidatesTags: ["logout"],
    }),
    loginViaGo: builder.mutation({
      query: (body) => ({
        url: "/auth/google/login",
        method: "POST",
        body,
      }),
      invalidatesTags: ["login"],
    }),
    loginViaFb: builder.mutation({
      query: (body) => ({
        url: "/auth/facebook/login",
        method: "POST",
        body,
      }),
      invalidatesTags: ["login"],
    }),
    loginViaGit: builder.mutation({
      query: (body) => ({
        url: "/auth/github/login",
        method: "POST",
        body,
      }),
      invalidatesTags: ["login"],
    }),
    forgotPassword: builder.mutation({
      query: (body) => ({
        url: "/auth/password/forgot",
        method: "POST",
        body,
      }),
      invalidatesTags: ["login"],
    }),
    resetPassword: builder.mutation({
      query: ({ body, query }) => ({
        url: `/auth/password/reset/${query}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["login"],
    }),
    newUser: builder.mutation({
      query: (body) => ({
        url: `/auth/email/verify`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["register"],
    }),
    verification: builder.mutation({
      query: (body) => ({
        url: `/auth/email-verification`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["register"],
    }),
  }),
});

export const {
  useLoginMutation,
  useLogOutMutation,
  useLoginViaGitMutation,
  useLoginViaGoMutation,
  useLoginViaFbMutation,
  useMeMutation,
  useIndividualRegisterMutation,
  useResetPasswordMutation,
  useForgotPasswordMutation,
  useNewUserMutation,
  useVerificationMutation
} = authApi;
