import { removeFromLocale } from "../methods"
import { setUserData, setAuthToken } from "@/store"

export const userLogout = () => {
  return dispatch => {
    removeFromLocale()
    dispatch(setAuthToken(''))
    dispatch(setUserData({}))
  }
}
