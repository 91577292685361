import { getFromLocale } from "@/utils";

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const paymentApi = createApi({
    reducerPath: "paymentApi",
    baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.API_FOR_LOGIN}/api`,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      headers.set("Accept", "application/json");
      headers.set("Authorization", `Bearer ${getFromLocale()}`);
      headers.set("Access-Control-Allow-Origin", "*");
      return headers;
    },
  }),
    tagTypes: ['payment'],
    endpoints: (builder) => ({
        payWithMillikart: builder.mutation({
            query: ({body,reference_page}) => ({
                params: { reference_page },
                url: `/payment/millikart/pay`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['payment'],
        }),
        payWithStripe: builder.mutation({
            query: (paymentData) => ({
                url: '/payment/stripe/pay',
                method: 'POST',
                body: paymentData,
            }),
            invalidatesTags: ['payment'],
        }),
        payWithParacoin: builder.mutation({
            query: (paymentData) => ({
                url: '/payment/paracoin/pay',
                method: 'POST',
                body: paymentData,
            }),
            invalidatesTags: ['payment'],
        }),
        payWithSquare: builder.mutation({
            query: (paymentData) => ({
                url: '/payment/squareup/pay',
                method: 'POST',
                body: paymentData,
            }),
            invalidatesTags: ['payment'],
        }),
        payWithPortmanat: builder.mutation({
            query: (paymentData) => ({
                url: '/payment/netcard/pay',
                method: 'POST',
                body: paymentData,
            }),
            invalidatesTags: ['payment'],
        }),
        payWithPayriff: builder.mutation({
            query: ({body,reference_page}) => ({
                params: { reference_page },
                url: '/payment/payriff/pay',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['payment'],
        })
    }),
});

export const { 
    usePayWithMillikartMutation, 
    usePayWithParacoinMutation, 
    usePayWithStripeMutation, 
    usePayWithSquareMutation,
    usePayWithPortmanatMutation,
    usePayWithPayriffMutation 
} = paymentApi
