

// web_hosting_table: {
//     title: "Our Recent Works",
//     subTitle: "We Are Proud to Show You"
// },


// export const panelTitleHandler = ({ sectionName, colourGroup, posGroup, bt }) => {
//     try {
//         const PANEL_TITLES = {
//             domain: {
//                 title: "Domain Name Search",
//                 subTitle: "Search domain name availability using our website domain checker tool. Simply type in your desired name and get instant results."
//             },
//             domain_table: {
//                 title: "Price List of Domain Names",
//                 subTitle: "We Hope You Will Choose the Best One for You."
//             },
//             trust: {
//                 title: "We Trust Ourselves",
//                 subTitle: "We Are Proud of That"
//             },
//             web_hosting: {
//                 title: "Fast & Secure Hosting for Any Website",
//                 subTitle:
//                   "Elevate your online presence with hosting that has your back with superior security, ultra-fast speed, and 24/7 friendly support.",
//               },
//               our_partners: {
//                 title: "Our Partners",
//                 subTitle:
//                   "Building Bridges, Creating Opportunities, Partnering for Success!",
//               },
//               web_hosting_table: {
//                 title: "Web Hosting Plans for Every Need",
//                 subTitle: "Your Path to the Perfect Hosting Plan Starts Here",
//               },
//             any_question: {
//                 title: "Do You Have Any Questions?",
//                 subTitle: "We are there for you whenever you need it! If you have any questions or problems, you can call us and write to our chat or e-mail address within the site. Please feel free to contact us."
//             },
//             vps_hosting: {
//                 title: "Be in Full Control with VPS Hosting",
//                 subTitle: "Now you can order the VPS Hosting plan that you can customize as per your wish."
//             },
//             vps_hosting_table: {
//                 title: "Select Perfect VPS Plan",
//                 subTitle: "Get Power and Speed at Great Prices With Our Virtual Private Servers."
//             },
//             d_server_table: {
//                 title: "Select Perfect Server Plan",
//                 subTitle: "Get Power and Speed at Great Prices With Our Dedicated Servers."
//             },
//             careers: {
//                 title: "We Are Looking for a Talented Co-worker",
//                 subTitle: "We are waiting for our talented friends to join our team. If you are suitable for one of our vacancies, apply now."
//             },
//             portfolio: {
//                 title: "Our Works Prepared with Unique Designs",
//                 subTitle: "The work we do is our pride, our name, our quality, in short, it is what makes us who we are."
//             },
//             testimonials: {
//                 title: "Reviews of Our Customers",
//                 subTitle: "Who Said What"
//             },
//             home: {
//                 title: "Find Your Perfect Domain Name",
//                 subTitle: "It All Starts With a Domain"
//             },
//             faq: {
//                 title: "How to Transfer Domain Name",
//                 subTitle: "Information You Need About the Domain Name Transfer"
//             },
//             blog: {
//                 title: "Our Valuable Blogs Written for You",
//                 subTitle: "You can follow our blog to read the latest news about Information Technologies or our articles that will be useful for you."
//             },
//             blog_details: {
//                 title: "How to protect against mobile advertising threats?",
//                 subTitle: "We have downloaded and loved many apps on our mobile devices. We often encounter mobile ad threats while using these apps. But how to protect yourself from these threats?"
//             },
//             about_us: {
//                 title: "A creative company that brings innovations",
//                 subTitle: "Should we call ourselves an innovative creative agency or a company? Or shall we say both?"
//             },
//             our_team: {
//                 title: "Our Rich Experienced Team",
//                 subTitle: "Let's get to know them"
//             },
//             job_details: {
//               title: "We are looking for a Front-end Developer",
//               subTitle:
//                 "Elevate your online presence with hosting that has your back with superior security, ultra-fast speed, and 24/7 friendly support.",
//             },
//             contact: {
//                 title: "Let's Get in Touch",
//                 subTitle:
//                   "Want to get in touch? We'd love to hear from you. You can contact us using the following form or other means of communication.",
//             },
//             application_form: {
//                 title: "Application Form for Teammate",
//             },
//             get_quote:{
//                 title:"Get a Free Quote",
//                 subTitle:"Expert Website Solutions at Competitive Prices"
//             }
//         }
//         const { title, subTitle } = PANEL_TITLES[sectionName]
//         const { tColour, subTColour } = colorGroups[colourGroup]
//         const mBt = bt ? "mb-[48px]" : ""
//         const { parentPos, childPos } = posGroups[posGroup]

//     return {
//       parentPos,
//       childPos,
//       title,
//       subTitle,
//       tColour,
//       subTColour,
//       mBt,
//     };
//   } catch (err) {
//     console.log(err);
//   }
// };
